import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import i18n from '../../i18n'
import i18next, { t } from 'i18next'
import { Link } from 'react-router-dom';
import { fetchSearchResults } from '../../api'
import PageHeader from '../Header/PageHeader';
import Loader from '../Loader'

const Results = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [oldId, setOldId] = useState(id);
    const currentLanguage = (i18n.language === 'en' ? 'en' : 'sq');
    const [lang, setLang] = useState(currentLanguage);
    const [isLoading, setLoading] = useState(true);
    const [count, setCount] = useState(1);
    const [hideButton, setHideButton] = useState(false);

    i18next.on('languageChanged', function (lng) {
        if (lng === 'sq') {
            setLang('sq')
        }
        else setLang('en')
        setLoading(true);
        clearState();
    });

    const clearState = () => {
        setData([]);
        setCount(1);
        setHideButton(false);
    }

    useEffect(() => {
        fetchSearchResults(id, lang, count).then((resp) => {
            if (id !== oldId) {
                clearState()
                setData([...resp]);
            } else {

                setData([...data, ...resp]);
            }
            setLoading(false);
            setOldId(id)

            if (resp.length < 6) {
                setHideButton(true)
            }
        })
    }, [lang, count, id]);

    return (
        <div className='container'>
            {data && !isLoading ?
                <>
                    <PageHeader header={t('searchResults')} title={t('searchTerm') + id} />
                    <div className='row'>
                        {data.length > 0 ? data.map(item => {
                            return (
                                <>
                                    <div className='col-12 col-md-6 search-item' key={item.id}>
                                        <Link to={`/post/${item.id}`} state={{ from: item.id }} className='link-underline'>
                                            {item.title}
                                        </Link>
                                    </div>
                                </>
                            )
                        }) : <div className='col-12 col-md-6 search-item'> {t('noResults')} </div>}
                    </div>

                    {!hideButton && <div className='d-flex justify-content-center'>
                        <button className='btn btn-blue' onClick={() => {
                            setCount(count + 1)
                        }}>
                            {t('loadMore')}
                        </button>
                    </div>}
                </> : <Loader />
            }
        </div>
    )
}

export default Results;