import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'
import FEAT_IMAGE from '../../../assets/images/feat_image_2.jpg'

const PostList = (props) => {
    const { postList } = props;

    return (
        <div className='row posts-list'>
            {postList.map((item, index) => {

                // let image_url = item.featured_image_src;

                // if (item.featured_image_src.indexOf('default.png') > -1) {
                //     image_url = FEAT_IMAGE
                // }

                const cleanExcerpt = item.excerpt.rendered.replace(/\[([^\[])*(\])/g, '');

                return (
                    <div className='col-12 col-md-4 post-item' key={item.id}>
                        <Link to={`/post/${item.slug}`} state={{ from: item }}>
                            <img className='posts-image' src={item?.featured_image_src.indexOf('default.png') > -1 ? FEAT_IMAGE : item?.featured_image_src} alt='' />
                        </Link>

                        <Link to={`/post/${item.slug}`} state={{ from: item }} className='link-underline'>
                            <h5 dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                        </Link>

                        <h6 className='post-date'>{
                            item?.date && moment(item?.date).format("DD.MM.YYYY")
                        }</h6>

                        <div dangerouslySetInnerHTML={{ __html: cleanExcerpt.slice(0, 120).concat('…') }} />
                    </div>
                )
            })}
        </div>
    )
}

export default PostList;