import { EmailShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton } from "react-share"
import { useTranslation } from 'react-i18next';

const SharePost = () => {
    const { t } = useTranslation();

    return (
        <div className="share-section">
            <h6 className='share'>{t('share_article')}</h6>
            <FacebookShareButton url={window.location.href} title="Facebook">
                <button className="btn btn-circle">
                    <i class="fab fa-facebook-f"></i>
                </button>
            </FacebookShareButton>

            <TwitterShareButton url={window.location.href} title={"Twitter"}>
                <button className="btn btn-circle">
                    <i className="fab fa-twitter"> </i>
                </button>
            </TwitterShareButton>

            <LinkedinShareButton url={window.location.href} title={"Linkedin"}>
                <button className="btn btn-circle">
                    <i className="fab fa-linkedin-in"> </i>
                </button>
            </LinkedinShareButton>

            <PinterestShareButton url={window.location.href} title={"Pinterest"}>
                <button className="btn btn-circle">
                    <i className="fab fa-pinterest-p"> </i>
                </button>
            </PinterestShareButton>

            <EmailShareButton url={window.location.href} title={"Email"}>
                <button className="btn btn-circle">
                    <i class="fas fa-envelope"></i>
                </button>
            </EmailShareButton>

            <button className="btn btn-circle">
                <i class="fas fa-copy" style={{ cursor: 'pointer' }} onClick={async () => {
                    await navigator.clipboard.writeText(window.location.href)
                }}></i>
            </button>
        </div>
    )

}

export default SharePost;