import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import PageHeader from '../../Header/PageHeader';
import programmeImage from '../../../assets/images/programme_image.jpg'

const Programmes = () => {
    const { t } = useTranslation();

    return (
        <div className='container'>
            <PageHeader header={t('programmes')} />

            <div className='row'>
                <div className='col-12 col-sm-6 col-lg-3'>
                    <div className='programmes'>
                        <NavLink
                            to="/programmes/gogo-club"
                            
                        >
                            <img className="rounded-circle" src={programmeImage} alt={t('navigation.gogoClub')}/>
                            
                            <h3 className='link-underline'>{t('navigation.gogoClub')}</h3>
                        </NavLink>
                        
                    </div>
                </div>

                <div className='col-12 col-sm-6 col-lg-3'>
                    <div className='programmes'>
                        <NavLink
                            to="/programmes/art-talks"
                            
                        >
                            <img className="rounded-circle" src={programmeImage} alt={t('navigation.artTalks')} />
                            
                            <h3 className='link-underline'>{t('navigation.artTalks')}</h3>
                        </NavLink>
                        
                    </div>
                </div>

                <div className='col-12 col-sm-6 col-lg-3'>
                    <div className='programmes'>
                        <NavLink
                            to="/programmes/workshops"
                            
                        >
                            <img className="rounded-circle" src={programmeImage} alt={t('navigation.workshops')} />
                            
                            <h3 className='link-underline'>{t('navigation.workshops')}</h3>
                        </NavLink>
                        
                    </div>
                </div>

                <div className='col-12 col-sm-6 col-lg-3'>
                    <div className='programmes'>
                        <NavLink
                            to="/programmes/residencies"
                            
                        >
                            <img className="rounded-circle" src={programmeImage} alt={t('navigation.residencies')} />
                            
                            <h3 className='link-underline'>{t('navigation.residencies')}</h3>
                        </NavLink>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Programmes;