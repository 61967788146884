import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import i18n from '../../../i18n'
import i18next, { t } from 'i18next'
import moment from 'moment'
import { fetchLatestPost, fetchImageById } from '../../../api'
import Loader from '../../Loader';
import FEAT_IMAGE from '../../../assets/images/feat_image_2.jpg'

const MainPost = (props) => {
    const [data, setData] = useState([]);
    const [imageLink, setImageLink] = useState();
    const currentLanguage = (i18n.language === 'en' ? 'en' : 'sq');
    const [lang, setLang] = useState(currentLanguage);

    const [isLoading, setLoading] = useState(true);

    i18next.on('languageChanged', function (lng) {
        if (lng === 'sq') {
            setLang('sq')
        }
        else setLang('en')
        setLoading(true);
        clearState();
    });

    const clearState = () => {
        setData([])
    }

    useEffect(() => {
        fetchLatestPost(lang).then((resp) => {
            setData(resp[0]);
            setLoading(false);
        })
    }, [lang]);

    // useEffect(() => {
    //     fetchImageById(data?.featured_media).then((resp) => {
    //         setImageLink(resp.source_url);
    //     }).catch(() => {
    //         setImageLink(FEAT_IMAGE);
    //     })
    // }, [data]);


    return (
        <div className='row latest-post'>
            {data && !isLoading ?
                <>
                    <div className='col-12 col-sm-6'>
                        <Link to={`/post/${data?.slug}`} state={{ from: data }}>
                            <img className='featured-image' src={data?.featured_image_src.indexOf('default.png') > -1 ? FEAT_IMAGE : data?.featured_image_src} alt='' />
                        </Link>
                    </div>
                    <div className='col-12 col-sm-6'>
                        <Link to={`/post/${data?.slug}`} state={{ from: data }} className='link-underline'>
                            <h1 dangerouslySetInnerHTML={{ __html: data?.title?.rendered }} />
                        </Link>

                        <h6 className='post-date'>{
                            data?.date && moment(data?.date).format("DD.MM.YYYY")
                        }</h6>

                        <div dangerouslySetInnerHTML={{ __html: data?.excerpt?.rendered.replace(/\[([^\[])*(\])/g, '').slice(0, 255).concat('…') }} />
                    </div>
                </> : <Loader />}
        </div>
    )
}

export default MainPost;