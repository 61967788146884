import Loader from '../../Loader';
import PageHeader from '../../Header/PageHeader';
import useFetchPage from '../../../hooks/useFetchPage';

const About = () => {
    const en = process.env.REACT_APP_WP_ABOUT_PAGE_EN;
    const sq = process.env.REACT_APP_WP_ABOUT_PAGE_SQ;

    const {data, isLoading} = useFetchPage(en, sq);

    return (
        <div className='container'>
            {data && !isLoading ? <>
                    <PageHeader header={'GQ'} title={data.title.rendered}/>
                    <div dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                    </> : <Loader />}
        </div>
    )
}

export default About