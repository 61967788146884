import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';

const LanguageChooser = () => {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <div className='form-group'>
            <div className='language-chooser'>
                <button className={'language-chooser-button' + (i18n.language === 'sq' ? ' active' : '')} type="button" onClick={() => changeLanguage('sq')}>al</button>
                <button className={'language-chooser-button' + (i18n.language === 'en' ? ' active' : '')} type="button" onClick={() => changeLanguage('en')}>en</button>
            </div>
        </div>
    )

}

export default withTranslation()(LanguageChooser);