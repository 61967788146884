const link = process.env.REACT_APP_WP_LINK;

const fetchPage = async (id) => {
    const res = await fetch(
        `${link}/wp-json/wp/v2/pages/${id}`,
    );
    const json = await res.json();
    return json;
};

const fetchPost = async (id) => {
    const res = await fetch(
        // `${link}/wp-json/wp/v2/posts/${id}`,
        `${link}/wp-json/wp/v2/posts?slug=${id}`
    );
    const json = await res.json();
    return json;
};

const fetchPostByCategory = async (id) => {
    const res = await fetch(
        `${link}/wp-json/wp/v2/posts?categories=${id}&per_page=3`,
    );
    const json = await res.json();
    return json;
};

const fetchAuthor = async (id) => {
    const res = await fetch(
        `${link}/wp-json/wp/v2/users/${id}`,
    );
    const json = await res.json();
    return json;
};

const fetchSearchResults = async (searchQuery, lang, page) => {
    // add custom post types
    const url = `${link}/wp-json/wp/v2/search?lang=${lang}&subtype=page&subtype=post&search=${searchQuery}&page=${page}&per_page=6`;
    const res = await fetch(
        url
    );
    const json = await res.json();
    return json;
};

const fetchLatestPost = async (language) => {
    const res = await fetch(
        `${link}/wp-json/wp/v2/posts?per_page=1&lang=${language}`
    );
    const json = await res.json();
    return json;
}

const fetchProgImage = async () => {
    const res = await fetch(
        `${link}/wp-json/wp/v2/home-page-image/3745`
    );
    const json = await res.json();

    return json;
}

const fetchDBImage = async () => {
    const res = await fetch(
        `${link}/wp-json/wp/v2/home-page-image/3744`
    );
    const json = await res.json();

    return json;
}

const fetchLatestNews = async (language, page, per_page) => {
    let url = '';
    if (per_page === 1) {
        url = `${link}/wp-json/wp/v2/posts?lang=${language}&per_page=3&page=${page}&offset=${per_page}`
    } else {
        url = `${link}/wp-json/wp/v2/posts?lang=${language}&per_page=3&page=${page}&offset=${per_page}`
    }
    const res = await fetch(
        url
        // `https://galeriaqahili.com/wp-json/wp/v2/posts?lang=${language}&per_page=3&page=${page}&offset=${per_page}`
    );
    const json = await res.json();
    return json;
};

const fetchImageById = async (id) => {
    const res = await fetch(
        `${link}/wp-json/wp/v2/media/${id}`
    );
    const json = await res.json();
    return json;
}

const fetchExhibitions = async (language, page) => {
    const res = await fetch(
        // `${link}/wp-json/wp/v2/exhibition?lang=${language}&per_page=6&page=${page}`
        `${link}/wp-json/wp/v2/exhibition?lang=${language}&per_page=3&page=${page}`
    );
    const json = await res.json();
    return json;
}

const fetchExhibition = async (id) => {
    const res = await fetch(
        `${link}/wp-json/wp/v2/exhibition?_embed&slug=${id}`
    );
    const json = await res.json();
    return json;
};

const fetchArchive = async (archive, language, page) => {
    const res = await fetch(
        // `${link}/wp-json/wp/v2/exhibition?lang=${language}&per_page=6&page=${page}`
        `${link}/wp-json/wp/v2/${archive}?lang=${language}&per_page=12&page=${page}`
    );
    const json = await res.json();
    return json;
}

const fetchArchiveItem = async (archiveItem, id) => {
    const res = await fetch(
        `${link}/wp-json/wp/v2/${archiveItem}?_embed&slug=${id}`
    );
    const json = await res.json();
    return json;
};

export {
    fetchPage,
    fetchLatestPost,
    fetchLatestNews,
    fetchPost,
    fetchSearchResults,
    fetchAuthor,
    fetchPostByCategory,
    fetchImageById,
    fetchProgImage,
    fetchDBImage,
    fetchArchive,
    fetchArchiveItem,
    fetchExhibitions,
    fetchExhibition
};