import { useState, useEffect } from 'react'
import i18n from '../../../i18n'
import i18next, { t } from 'i18next'
import { fetchLatestNews } from '../../../api'
import PageHeader from '../../Header/PageHeader'
import Loader from '../../Loader'
import MainPost from './MainPost'
import PostList from './PostsList'

const LatestNews = () => {
    const [data, setData] = useState([]);
    const currentLanguage = (i18n.language === 'en' ? 'en' : 'sq');
    const [lang, setLang] = useState(currentLanguage);
    const [count, setCount] = useState(1);

    const [perPage, setPerPage] = useState(1);
    const [isLoading, setLoading] = useState(true);

    i18next.on('languageChanged', function (lng) {
        if (lng === 'sq') {
            setLang('sq')
        }
        else setLang('en')
        setLoading(true);
        clearState();
    });

    const clearState = () => {
        setData([])
        setCount(1);
    }

    useEffect(() => {
        fetchLatestNews(lang, count, perPage).then((resp) => {
            setData([...data, ...resp]);
            setLoading(false);
        })
    }, [lang, count]);

    return (
        <div className='container'>
            {data && !isLoading ? <>
                <PageHeader header={t('latest')} />

                <MainPost />

                <PostList postList={data} />

                <div className='d-flex justify-content-center'>
                    <button className='btn btn-blue' onClick={() => {
                        setCount(count + 1)
                        setPerPage(perPage * 3 + 1)
                    }}>
                        {t('loadMore')}    
                    </button>
                </div>

            </> : <Loader />}
        </div>
    )
}

export default LatestNews