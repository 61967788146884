import { useTranslation } from 'react-i18next';
import Loader from '../../Loader';
import PageHeader from '../../Header/PageHeader';
import useFetchPage from '../../../hooks/useFetchPage';

const Residencies = (props) => {
    const { t } = useTranslation();
    
    const en = process.env.REACT_APP_WP_RESIDENCIES_PAGE_EN;
    const sq = process.env.REACT_APP_WP_RESIDENCIES_PAGE_SQ;

    const {data, isLoading} = useFetchPage(en, sq);

    return (
        <div className='container'>
            {data && !isLoading ? <>
                    <PageHeader header={t('programmes')} title={data.title.rendered}/>
                    <div dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                    </> : <Loader />}
        </div>
    )
}

export default Residencies;