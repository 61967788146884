import React, { useEffect } from 'react';
import './assets/sass/App.scss';
import {
  BrowserRouter as Router,
  Link,
  Outlet,
  useParams
} from 'react-router-dom';
import './assets/icons/fontawesome-pro-5.11.2-web/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'

import Routes from './Routes';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import DB_LOGO from './assets/images/db_logo.svg'
import ReactGA from 'react-ga4'

import { HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

// import ReactGA from 'react-ga';
// const TRACKING_ID = "UA-157591174-1"; // OUR_TRACKING_ID
ReactGA.initialize("G-3HVGY7CKKM");
ReactGA.send("pageview");

function App() {
  // let location = useLocation();

  // React.useEffect(() => {
  //   // Google Analytics
  //   ReactGA.pageview('send', 'pageview');
  // }, [location]);

  // const location = useLocation()
  // location = location.pathname
  
  // const history = useHistory()
  // history = history.location.pathname

  return (
    <>
    <HelmetProvider>

      <Router>
        <Header />
        <Routes />
        <Footer />
      </Router>
      </HelmetProvider>

    </>
  );
}

export default App;