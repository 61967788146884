import React, { useEffect, useState } from "react";

const Overlay = (props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, props.delay);
  }, [props.delay]);

  return visible ?
    <div className="contact-overlay">
      <div className="contact-overlay-content">
        {props.children}
      </div>
    </div>
    : <div />;
};

export default Overlay;
