import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { fetchPostByCategory } from '../../../api';
import Loader from '../../Loader'
import FEAT_IMAGE from '../../../assets/images/feat_image_2.jpg'

const RelatedPosts = (category_id) => {
    const id = category_id.category_id;

    const { t } = useTranslation();

    const [data, setData] = useState();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        fetchPostByCategory(id).then((resp) => {
            setData(resp);
            setLoading(false);
        })
    }, [id]);

    return (
        (data && !isLoading ?
            <div className='related-post-section'>
                <h1>{t('related_articles')}</h1>

                <div className='row'>
                    {data.map((item, index) => {
                        return (
                            <div className='col-12 col-md-4 related-post-item' key={item.id}>
                                <Link to={`/post/${item.slug}`} state={{ from: item }}>
                                    <img className='related-post-image' src={item?.featured_image_src.indexOf('default.png') > -1 ? FEAT_IMAGE : item?.featured_image_src} alt='' />
                                </Link>

                                <Link to={`/post/${item.slug}`} state={{ from: item }} className='link-underline'>
                                    <h5 dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div> : <Loader />
        )
    )
}

export default RelatedPosts