import { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LOGO from '../../assets/images/logo.svg';
import Search from '../Search/Search';
import LanguageChooser from './LanguageChooser';
import ReactGA from 'react-ga';

const Header = () => {
    const { t } = useTranslation();

    const [showSearchBar, setShowSearchBar] = useState(false);

    const handleSearchBar = e => {
        e.preventDefault();
        setShowSearchBar(!showSearchBar);
    }

    const [activeMenu, setActiveMenu] = useState('whatsOn')

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 991;

    const TRACKING_ID = "UA-157591174-1"; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);

    const handleGA = e => {
        e.preventDefault();
        ReactGA.pageview('explore')
    }

    return (
        <div className='container'>

            <div className="d-none d-lg-block">
                <LanguageChooser />
            </div>

            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <Link to='/' style={{ padding: 5 }} onClick={() => { setActiveMenu('whatsOn') }} >
                        <img className='header-logo' src={LOGO} alt={t('galeria_qahili')} />
                    </Link>
                    <button className="navbar-toggler" id="navbarBtn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarScroll">
                        {isMobile ?
                            <div className='d-block d-lg-none'>
                                <ul className="mobile-navbar navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                    <li className="nav-item d-block d-sm-block d-md-block d-lg-none">
                                        <LanguageChooser />
                                    </li>

                                    <li className="nav-item dropdown">
                                        <a
                                            className={activeMenu === 'whatsOn' ? 'active nav-link' : 'nav-link'}
                                            role="button" data-bs-toggle="dropdown"
                                            id="navbarScrollingDropdown" aria-expanded="false">
                                            {t('navigation.whatson')}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                            <li>
                                                <NavLink
                                                    to="/"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('whatsOn'); }}
                                                >
                                                    <span data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll">
                                                        {t('navigation.currently_on_view')}
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/programmes"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('whatsOn') }}
                                                >
                                                    <span data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll">
                                                        {t('navigation.programmes')}
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/open-call"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('whatsOn') }}
                                                >
                                                    <span data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll">
                                                        {t('navigation.openCall')}
                                                    </span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    {/* <li className="nav-item dropdown">
                                        <a className={activeMenu === 'archive' && activeMenu !== 'whatsOn' ? 'active nav-link' : 'nav-link'}
                                            role="button" data-bs-toggle="dropdown"
                                            id="navbarScrollingDropdown3" aria-expanded="false">
                                            {t('navigation.archive')}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown3">
                                            <li>
                                                <NavLink
                                                    to="/exhibitions"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('archive'); setShowSearchBar(false) }}
                                                >
                                                    {t('navigation.exhibitions')}
                                                </NavLink>
                                            </li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a target="_blank" href="https://databaza.art/">databaza.art</a></li>
                                        </ul>
                                    </li> */}

                                    <li className="nav-item">
                                        <NavLink
                                            to="/latest-news"
                                            onClick={() => { setActiveMenu('news') }}
                                            className={({ isActive }) => (isActive ? 'active nav-link' : 'inactive nav-link')}
                                        >
                                            <span data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll">
                                                {t('navigation.latest')}
                                            </span>
                                        </NavLink>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <a
                                            className={activeMenu === 'gq' ? 'active nav-link' : 'nav-link'}
                                            role="button" data-bs-toggle="dropdown"
                                            id="navbarScrollingDropdown4" aria-expanded="false">
                                            GQ
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown4">
                                            <li>
                                                <NavLink
                                                    to="/about"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('gq') }}
                                                >
                                                    <span data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll">
                                                        {t('navigation.about')}
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/support"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('gq') }}
                                                >
                                                    <span data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll">
                                                        {t('navigation.support')}
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/location_and_hours"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('gq') }}
                                                >
                                                    <span data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll">
                                                        {t('navigation.location')}
                                                    </span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/contact"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('gq') }}
                                                >
                                                    <span data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll">
                                                        {t('navigation.contact')}
                                                    </span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item d-block d-sm-block d-md-block d-lg-none">
                                        <Search showSearchBar={true} isMobile={isMobile} />
                                    </li>
                                </ul>
                            </div> : <>
                                <ul className="desktop-navbar navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ">
                                    <li className="nav-item d-block d-sm-block d-md-block d-lg-none">
                                        <LanguageChooser />
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a
                                            className={activeMenu === 'whatsOn' && activeMenu !== 'news' ? 'active nav-link' : 'nav-link'}
                                            id="navbarScrollingDropdown" aria-expanded="false">
                                            {t('navigation.whatson')}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                            <li>
                                                <NavLink
                                                    to="/"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('whatsOn'); setShowSearchBar(false); handleGA() }}
                                                >
                                                    {t('navigation.currently_on_view')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/programmes"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('whatsOn'); setShowSearchBar(false) }}
                                                >
                                                    {t('navigation.programmes')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/open-call"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('whatsOn'); setShowSearchBar(false) }}
                                                >
                                                    {t('navigation.openCall')}
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    {/* <li className="nav-item dropdown">
                                        <a className={activeMenu === 'archive' && activeMenu !== 'whatsOn' ? 'active nav-link' : 'nav-link'}
                                            id="navbarScrollingDropdown3" aria-expanded="false">
                                            {t('navigation.archive')}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown3">
                                            <li>
                                                <NavLink
                                                    to="/exhibitions"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('archive'); setShowSearchBar(false) }}
                                                >
                                                    {t('navigation.exhibitions')}
                                                </NavLink>
                                            </li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a target="_blank" href="https://databaza.art/">databaza.art</a></li>
                                        </ul>
                                    </li> */}

                                    <li className="nav-item">
                                        <NavLink
                                            to="/latest-news"
                                            onClick={() => { setActiveMenu('news'); setShowSearchBar(false) }}
                                            className={({ isActive }) => (isActive ? 'active nav-link' : 'inactive nav-link')}
                                        >
                                            {t('navigation.latest')}
                                        </NavLink>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <a
                                            className={activeMenu === 'gq' && activeMenu !== 'whatOn' ? 'active nav-link' : 'nav-link'}

                                            id="navbarScrollingDropdown4" aria-expanded="false">
                                            GQ
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown4">
                                            <li>
                                                <NavLink
                                                    to="/about"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('gq'); setShowSearchBar(false) }}
                                                >
                                                    {t('navigation.about')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/support"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('gq'); setShowSearchBar(false) }}
                                                >
                                                    {t('navigation.support')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/location_and_hours"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('gq'); setShowSearchBar(false) }}
                                                >
                                                    {t('navigation.location')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/contact"
                                                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                                                    onClick={() => { setActiveMenu('gq'); setShowSearchBar(false) }}
                                                >
                                                    {t('navigation.contact')}
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item d-block d-sm-block d-md-block d-lg-none">
                                        <Search showSearchBar={true} />
                                    </li>
                                </ul>
                                <button type="button" className="show-search-button d-none d-lg-block" onClick={handleSearchBar}>
                                    <i className="fas fa-search"></i>
                                </button>
                            </>
                        }
                    </div>
                </div>
            </nav>

            <div className="d-none d-lg-block">
                <Search showSearchBar={showSearchBar} setShowSearchBar={setShowSearchBar} />
            </div>
        </div>
    )
}

export default Header;