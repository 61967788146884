import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchImageById } from '../../../api';
import logo from '../../../assets/images/logo.svg'

function PostAuthor(author) {
    const { t } = useTranslation();
    const [data, setData] = useState('');

    const name = author.author_name !== '' ? author.author_name : 'Galeria Qahili';
    const desc = author.author_desc !== '' ? author.author_desc : '';
    const image = author.author_image !== '' ? author.author_image : logo;

    useEffect(() => {
        fetchImageById(image).then((resp) => {
            setData(resp.source_url);
        })
    }, [image]);

    return (
        <div className="author-section">
            <div className="row">
                <div className="col-2 col-sm-1">
                    <img className='author-image' src={logo} alt={name} />
                </div>

                <div className="col-10 col-sm-11">
                    <h6 className='author-name'>{t('written_by')} {name}</h6>
                    <p>{desc}</p>
                </div>
            </div>
        </div>
    );
}

export default PostAuthor;