import MailchimpSubscribe from 'react-mailchimp-subscribe';
import NewsletterForm from './NewsletterForm';

const NewsletterSubscribe = () => {

  const url = process.env.REACT_APP_MAILCHIMP_URL;

  const SimpleForm = () => <MailchimpSubscribe url={url}/>

  return (
    <MailchimpSubscribe
      url={ url }
      render={ ( props ) => {
        const { subscribe, status, message } = props || {};
        return (
          <NewsletterForm
            status={ status }
            message={ message }
            onValidated={ formData => subscribe( formData ) }
          />
        );
      } }
    />
  );
};

export default NewsletterSubscribe;