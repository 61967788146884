import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import NewsletterSubscribe from './NewsletterSubscribe';
import WHITE_LOGO from '../../assets/images/logo_white.svg'

const Footer = () => {
    const { t } = useTranslation();

    const currentYear = new Date().getFullYear();

    return (
        <div className='footer'>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-12 col-sm-4'>
                        <ul>
                            <li>{t('galeria_qahili')}</li>
                            <li>{t('footer.address_line_1')}</li>
                            <li>{t('footer.address_line_2')}</li>
                            <li>{t('footer.address_line_3')}</li>
                            <li>{t('footer.address_line_4')}</li>
                        </ul>

                        <p>{t('footer.opening_hours')}</p>

                        <div className='social-media'>
                            <a href='https://www.facebook.com/GaleriaQahili/' target='_blank' className='link-underline'>
                                <i class='fab fa-facebook-f'></i>
                            </a>

                            <a href='https://www.instagram.com/galeriaqahili/' target='_blank' className='link-underline'>
                                <i class='fab fa-instagram'></i>
                            </a>
                        </div>
                    </div>

                    <div className='col-12 col-sm-4'>
                        <a href='https://databaza.art/' target='_blank' className='link-underline'>
                            {t('databaza')}
                        </a>
                        <p>{t('footer.historic_database')}</p>
                    </div>

                    <div className='col-12 col-sm-4'>
                        <ul className='footer-links'>
                            <li>
                                <NavLink
                                    to="/about"
                                    className='link-underline'
                                >
                                    {t('footer.about_us')}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/programmes"
                                    className='link-underline'
                                >
                                    {t('footer.programmes')}
                                </NavLink>
                            </li>
                            <li>
                                <a href='https://databaza.art/' target='_blank' className='link-underline'>
                                    {t('databaza')}
                                </a>
                            </li>
                            <li>
                                <NavLink
                                    to="/support"
                                    className='link-underline'
                                >
                                    {t('footer.support')}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/privacy-policy"
                                    className='link-underline'
                                >
                                    {t('footer.privacy_policy')}
                                </NavLink>
                                </li>
                        </ul>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12 col-sm-6 col-lg-4'>
                        {/* <div className='outlined-textfield subscribe'>
                            <input className='input-border' type='email' placeholder=' ' />
                            <label className='input-label'>{t('enter_email')}</label>
                            <button className='btn-subscribe' type='submit'><i class='far fa-envelope'></i></button>
                        </div> */}

                        <NewsletterSubscribe />

                        <div>
                            <img className='footer-logo' src={WHITE_LOGO} alt={t('galeria_qahili')} />

                            <p className='copyright-text'>&copy; {currentYear} {t('galeria_qahili')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Footer;