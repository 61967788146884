import React, { Component, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import ScrollToTop from './hooks/scrollToTop'

import HomePage from './components/Home'
import ResultsPage from './components/Search/Results'
import ProgrammesPage from './components/Pages/Programmes/Programmes'
import GoGoClubPage from './components/Pages/Programmes/GoGoClub'
import ArtTalksPage from './components/Pages/Programmes/ArtTalks'
import WorkshopsPage from './components/Pages/Programmes/Workshops'
import ResidenciesPage from './components/Pages/Programmes/Residencies'
import OpenCallPage from './components/Pages/OpenCall/OpenCall'
import FAQPage from './components/Pages/OpenCall/FAQ'
// import ExhibitionsPage from './components/Pages/Archives/Exhibitions'
// import ExhibitionSinglePage from './components/Pages/Archives/Exhibition'
import LatestNewsPage from './components/Pages/Posts/Posts'
import PostPage from './components/Pages/Posts/Post'
import AboutPage from './components/Pages/GQ/About'
import SupportPage from './components/Pages/GQ/Support'
import LocationPage from './components/Pages/GQ/Location'
import ContactPage from './components/Pages/GQ/Contact'
import PrivacyPolicyPage from './components/Pages/GQ/PrivacyPolicy'
// import PricingPage from './views/PricingPage'

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-157591174-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const routes = [
    {
        path: '/',
        name: 'Home',
        element: <HomePage />,
        exact: true,
    },
    {
        path: '/search=:id',
        name: 'ResultsPage',
        element: <ResultsPage />,
    },
    // What's on
    {
        path: '/programmes',
        name: 'ProgrammesPage',
        element: <ProgrammesPage />,
    },
    {
        path: '/programmes/gogo-club',
        name: 'GoGoClubPage',
        element: <GoGoClubPage />,
    },
    {
        path: '/programmes/art-talks',
        name: 'ArtTalksPage',
        element: <ArtTalksPage />,
    },
    {
        path: '/programmes/workshops',
        name: 'WorkshopsPage',
        element: <WorkshopsPage />,
    },
    {
        path: '/programmes/residencies',
        name: 'ResidenciesPage',
        element: <ResidenciesPage />,
    },
    {
        path: '/open-call',
        name: 'OpenCallPage',
        element: <OpenCallPage />,
    },
    {
        path: '/faq',
        name: 'FAQ',
        element: <FAQPage />,
    },
    // // Archive
    // {
    //     path: '/exhibitions',
    //     name: 'Exhibitions',
    //     element: <ExhibitionsPage />,
    // },
    // {
    //     path: '/exhibitions/:title',
    //     name: 'Exhibition',
    //     element: <ExhibitionSinglePage />,
    // },
    // Posts
    {
        path: '/latest-news',
        name: 'LatestNewsPage',
        element: <LatestNewsPage />,
    },
    {
        path: '/post/:title',
        name: 'PostPage',
        element: <PostPage />,
    },
    // GQ
    {
        path: '/about',
        name: 'AboutPage',
        element: <AboutPage />,
    },
    {
        path: '/support',
        name: 'SupportPage',
        element: <SupportPage />,
    },
    {
        path: '/location_and_hours',
        name: 'LocationAndHoursPage',
        element: <LocationPage />,
    },
    {
        path: '/contact',
        name: 'ContactPage',
        element: <ContactPage />,
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicyPage',
        element: <PrivacyPolicyPage />,
    }
]

class Raoutes extends Component {
    
    render() {
        return (
            <>
                <ScrollToTop />
                <Routes>
                    {/* <IndexRoute component={HomePage} /> */}
                    {routes.map((props, i) => (
                        <Route key={i} {...props} />
                    ))}
                    {/* <Route component={FourOFourPage} path="*" /> */}
                </Routes>
            </>
        )
    }
}

export default Raoutes
