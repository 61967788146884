import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const { t } = useTranslation();
    // const [show, setShow] = useState(false);
    const [form, setForm] = useState({
        name: "",
        birthdate: "",
        cityCountry: "",
        email: "",
        phoneNumber: "",
        category: "Solo exhibition",
        nameExhibitionProject: "",
        documentLink: ""
    })

    // const [file, setFile] = useState()

    // function handleFileChange(event) {
    //     setFile(event.target.files[0])
    // }


    const sendOpenCallApplication = (e) => {
        e.preventDefault();

        // setShow(true);

        emailjs.sendForm('service_3qud6er', 'template_e0worac', '#openCallForm', 'user_cBRJCMlt8S7GG73F375Ob')
            .then((result) => {
                // console.log(result.text);
                // setShow(false);
                setForm({
                    name: "",
                    birthdate: "",
                    cityCountry: "",
                    email: "",
                    phoneNumber: "",
                    category: "Solo exhibition",
                    nameExhibitionProject: "",
                    messageText: ""
                })
            }, (error) => {
                console.log(error.text);
                // setShow(false);
            });
    }

    return (
        <>
            <div className=' contact-form'>
                <form onSubmit={sendOpenCallApplication} id='openCallForm'>
                    <div className='row'>
                        <div className='col-12 col-sm-6'>
                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='name'
                                    placeholder=' '
                                    onChange={e => setForm({ name: e.target.value })}
                                    required />
                                <label className='input-label'>{t('openCallApp.name')}</label>
                            </div>

                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='date'
                                    name='birthdate'
                                    placeholder=' '
                                    onChange={e => setForm({ birthdate: e.target.value })}
                                    required />
                                <label className='input-label'>{t('openCallApp.birthdate')}</label>
                            </div>

                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='city_country'
                                    placeholder=' '
                                    onChange={e => setForm({ cityCountry: e.target.value })}
                                    required />
                                <label className='input-label'>{t('openCallApp.city_country')}</label>
                            </div>

                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='email'
                                    placeholder=' '
                                    onChange={e => setForm({ email: e.target.value })}
                                    required
                                />
                                <label className='input-label'>{t('openCallApp.email')}</label>
                            </div>

                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='phoneNumber'
                                    placeholder=' '
                                    onChange={e => setForm({ phoneNumber: e.target.value })}
                                    required
                                />
                                <label className='input-label'>{t('openCallApp.phone_number')}</label>
                            </div>

                            <div className='outlined-radiobutton'>
                                <label className='input-label'>{t('openCallApp.category')}</label>
                                <div className="form-check">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="category1" value={t('openCallApp.category_solo')} onChange={e => setForm({ category: e.target.value })} checked />
                                    <label class="form-check-label" htmlFor="category1">{t('openCallApp.category_solo')}</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="category2" value={t('openCallApp.category_group')} onChange={e => setForm({ category: e.target.value })} />
                                    <label class="form-check-label" htmlFor="category2">{t('openCallApp.category_group')}</label>
                                </div>
                                <div className="form-check">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="category3" value={t('openCallApp.category_student')} onChange={e => setForm({ category: e.target.value })} />
                                    <label class="form-check-label" htmlFor="category3">{t('openCallApp.category_student')}</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="category4" value={t('openCallApp.category_community')} onChange={e => setForm({ category: e.target.value })} />
                                    <label class="form-check-label" htmlFor="category4">{t('openCallApp.category_community')}</label>
                                </div>
                            </div>

                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='name_exhibition_project'
                                    placeholder=' '
                                    onChange={e => setForm({ nameExhibitionProject: e.target.value })}
                                    required
                                />
                                <label className='input-label'>{t('openCallApp.name_exhibition_project')}</label>
                            </div>

                            <div className="document-section">
                                <div className='outlined-textfield'>
                                    <input
                                        className='input-border'
                                        type='text'
                                        name='document-link'
                                        placeholder=' '
                                        onChange={e => setForm({ documentLink: e.target.value })}
                                        required
                                    />
                                    <label className='input-label'>{t('openCallApp.documents')}</label>
                                </div>
                                <p>{t('openCallApp.documents_more_info')}</p>
                            </div>

                        </div>
                    </div>

                    <button
                        type="submit"
                        className="btn btn-blue"
                    > {t('contact_page.submit')}</button>
                </form>
            </div>
            {/* {show &&
                <h1>alsdhalsdhasldasd</h1>} */}
        </>
    );
};

export default Contact