import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Search = (props) => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const setShowSearchBar = props.setShowSearchBar;

    const handleSubmit = e => {
        e.preventDefault();

        if (searchQuery !== '') navigate(`/search=${searchQuery}`);

        // https://galeriaqahili.com/wp-json/wp/v2/search/?subtype=page&subtype=post&search=mina
    };

    return (
        <form className='d-flex' onSubmit={handleSubmit}>
            <div className={'search-bar ' + (props.showSearchBar ? 'show' : 'hidden')}>
                <div className='outlined-textfield search'>
                    <input className='input-border' type='search' placeholder=' ' onChange={e => setSearchQuery(e.target.value)} />
                    <label className='input-label'>{t('Search')}</label>
                    {props.isMobile ?
                        <button className='btn-search' type='submit' data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll">
                            <i className='far fa-search'></i>
                        </button> :
                        <button className='btn-search' type='submit' onClick={() => {
                            setShowSearchBar(false);
                          }}>
                            <i className='far fa-search'></i>
                        </button>
                    }
                </div>
            </div>
        </form>
    )
}

export default Search;