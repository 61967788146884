import { useTranslation } from 'react-i18next';
import OpenCallForm from './OpenCallForm';
import Loader from '../../Loader';
import PageHeader from '../../Header/PageHeader';
import useFetchPage from '../../../hooks/useFetchPage';

const OpenCall = () => {
    const { t } = useTranslation();

    const en = process.env.REACT_APP_WP_OPENCALL_PAGE_EN;
    const sq = process.env.REACT_APP_WP_OPENCALL_PAGE_SQ;

    const {data, isLoading} = useFetchPage(en, sq);



    return (
        <div className='container'>
            { data && !isLoading ? <>
                    <PageHeader header={t('navigation.openCall')} />
                    <div dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                    <OpenCallForm />
                    </> : <Loader />}
        </div>
    )
    
}

export default OpenCall;