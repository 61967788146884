import Loader from '../../Loader';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../Header/PageHeader';
import Accordion from './Accordion';

const FAQ = () => {
    const { t } = useTranslation();
    const faq_text = t('faq', { returnObjects: true })

    return (
        <div className='container'>
            {t("access_and_visitor_information") ? <>
                <PageHeader header={'FAQ'} />

                <Accordion title={t('faq.access_and_visitor_information_title')} content={faq_text.access_and_visitor_information} id={1}/>
                
                <Accordion title={t('faq.exhibition_and_collections_title')} content={faq_text.exhibition_and_collections} id={2}/>

                <Accordion title={t('faq.education_title')} content={faq_text.education} id={3}/>

                <Accordion title={t('faq.copyrights_title')} content={faq_text.copyrights} id={4}/>

                <Accordion title={t('faq.job_and_internships_title')} content={faq_text.job_and_internships} id={5}/>

                <Accordion title={t('faq.open_call_title')} content={faq_text.open_call} id={6}/>

            </> : <Loader />}
        </div>
    )
}

export default FAQ