import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link, NavLink } from 'react-router-dom';
import i18n from '../i18n'
import i18next, { t } from 'i18next'
import moment from 'moment'
import { fetchLatestPost, fetchProgImage, fetchDBImage } from '../api'
import FEAT_IMAGE from '../assets/images/feat_image_2.jpg'
import Loader from './Loader'
import Databaza from './Home/Databaza';
import Programmes from './Home/Programmes';

const Home = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const currentLanguage = (i18n.language === 'en' ? 'en' : 'sq');
    const [lang, setLang] = useState(currentLanguage);

    const [isLoading, setLoading] = useState(true);

    i18next.on('languageChanged', function (lng) {
        if (lng === 'sq') {
            setLang('sq')
        }
        else setLang('en')
        setLoading(true);
        clearState();
    });

    const clearState = () => {
        setData([])
    }

    useEffect(() => {
        fetchLatestPost(lang).then((resp) => {
            setData(resp[0]);
            setLoading(false);
        })
    }, [lang]);

    return (

        <div className="container">
            {data && !isLoading ?
                <>
                    {/* <div className="col-12 home-section home-section-grey">
                <div className="home-section-inner row">
                <div className="col-12 col-sm-7 d-block d-sm-none">
                        <h1 className="home-section-title">{t('home.whats_new')}</h1>
                    </div>
    
                    <div className="col-12 col-sm-5">
                        <h2 className="home-section-subtitle">{t('home.whats_new_title')}</h2>
                        <p>{t('home.whats_new_desc')}</p>
                        <NavLink
                            to="/programmes"
                            className="home-section-link"
                        >
                            {t('home.whats_new_link')} <i class="fas fa-angle-right"></i>
                        </NavLink>
                    </div>

                    <div className="col-12 col-sm-7 d-none d-sm-block">
                        <h1 className="home-section-title">{t('home.whats_new')}</h1>
                    </div>
                </div>
            </div> */}

                    {/* <div className="col-12 home-section home-section-blue">
                <div className="home-section-inner row">
                    <div className="col-12 col-sm-7">
                        <h1 className="home-section-title">{t('home.on_view')}</h1>
                    </div>

                    <div className="col-12 col-sm-5">
                        <h2 className="home-section-subtitle">{t('home.on_view_title')}</h2>
                        <p>{t('home.on_view_desc')}</p>
                        <a className="home-section-link" href="">{t('home.on_view_link')} <i class="fas fa-angle-right"></i></a>
                    </div>
                </div>
            </div> */}

                    <div className="col-12 home-section home-section-temp">
                        <div className="home-section-inner row">

                            <>
                                <div className="col-12 col-lg-7">
                                    <h1 className="home-section-title">{t('home.whats_new')}</h1>

                                    <div className='home-image d-none d-sm-block'>
                                        <img className='home-section-image' src={data?.featured_image_src.indexOf('default.png') > -1 ? FEAT_IMAGE : data?.featured_image_src} alt='' />
                                    </div>
                                </div>


                                <div className="col-12 col-lg-5">
                                    <>
                                        <h2 className="home-section-subtitle" dangerouslySetInnerHTML={{ __html: data?.title?.rendered }} />
                                        <p dangerouslySetInnerHTML={{ __html: data?.excerpt?.rendered.replace(/\[([^\[])*(\])/g, '').slice(0, 255).concat('…') }} />

                                        <Link to={`/post/${data?.slug}`} state={{ from: data }} className="link-underline home-section-link">
                                            <div style={{ display: "inline" }}> {t('readMore')} <i class="fas fa-angle-right"></i> </div>
                                        </Link>
                                    </>

                                </div>
                            </>
                        </div>
                    </div>


                    <Programmes />

                    <Databaza />

                </> : <Loader />}
        </div>
    );
}

export default Home;