import { useState, useEffect } from 'react';
import i18n from '../i18n';
import i18next from 'i18next';
import { fetchPage } from '../api';

const useFetchPage = (en, sq) => {
    const [data, setData] = useState();
    const [isLoading, setLoading] = useState(true);

    const currentLanguage = (i18n.language === 'en' ? en : sq);
    const [lang, setLang] = useState(currentLanguage);

    i18next.on('languageChanged', function (lng) {
        if (lng === 'sq') {
            setLang(sq)
        }
        else setLang(en)
        setLoading(true);
    });

    useEffect(() => {
        fetchPage(lang).then((resp) => {
            setData(resp)
            setLoading(false);
        })
    }, [lang]);

    return {data, isLoading}
}

export default useFetchPage;