import { useTranslation } from 'react-i18next';

const Accordion = (faq_content) => {
    const { t } = useTranslation();

    const accordion_id = faq_content.id;

    const map_faq_text = (a1, a2) =>
        a1.map((x, i) => {
            return (
                <div class="accordion-item">
                    <h2 class="accordion-header" id={`heading-${accordion_id}-${i}`}>
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${accordion_id}-${i}`} aria-expanded="false" aria-controls={`collapse-${accordion_id}-${i}`}>
                            {x}
                        </button>
                    </h2>
                    <div id={`collapse-${accordion_id}-${i}`} class="accordion-collapse collapse" aria-labelledby={`heading${i}`} data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div dangerouslySetInnerHTML={{ __html: a2[i] }}></div>
                        </div>
                    </div>
                </div>
            )
        })

    return (
        <div className='faq-section'>
            <h4>{faq_content.title}</h4>

            <div class="accordion" id="accordionExample">

                {map_faq_text(
                    faq_content.content.questions,
                    faq_content.content.answers
                )}
            </div>
        </div>
    )

}

export default Accordion;