import { useTranslation } from 'react-i18next';

const NewsletterForm = ({ status, message, onValidated }) => {
    const { t } = useTranslation();

    let email;
    const handleFormSubmit = () =>
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value
        });

    const handleInputKeyEvent = (event) => {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            event.preventDefault();
            handleFormSubmit();
        }
    }

    return (
        <div>

            <div className='outlined-textfield subscribe'>
                <input ref={node => (email = node)} className='input-border' type='email' placeholder=' ' onKeyUp={(event) => handleInputKeyEvent(event)} />
                <label className='input-label'>{t('enter_email')}</label>


                {status === "sending" ?
                    <button className='btn-subscribe' onClick={handleFormSubmit} disbaled><i class="fas fa-spinner-third fa-spin"></i></button> :
                    <button className='btn-subscribe' onClick={handleFormSubmit} ><i class='far fa-envelope'></i></button>}

                {status === "error" && (
                    <div
                        className="subscribe-message"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {status === "success" && (
                    <div
                        className="subscribe-message"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}

            </div>
        </div>
    );
};

export default NewsletterForm