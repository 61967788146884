import { useTranslation } from 'react-i18next';
import PageHeader from '../../Header/PageHeader';
import GMap from './GoogleMap';

const Location = () => {
    const { t } = useTranslation();

    return (
        <div className='container'>
            <PageHeader header={t('gq')} title={t('locationPage.location')} />

            <div className='location-address'>
                <p>{t('locationPage.address_line_1')}</p>
                <p>{t('locationPage.address_line_2')}</p>
                <p>{t('locationPage.address_line_3')}</p>
                <p>{t('locationPage.address_line_4')}</p>
            </div>

            <div className='location-map'>
                <GMap />
            </div>

            <PageHeader title={t('locationPage.hours')} />

            <div className='location-hours'>
                <p>{t('locationPage.time')}</p>
                <p>{t('locationPage.days')}</p>
                <p>{t('locationPage.open')}</p>
                <p><div dangerouslySetInnerHTML={{ __html: t('locationPage.schedule') }}></div></p>
                <p>{t('locationPage.closed')}</p>
            </div>

            <PageHeader title={t('locationPage.admission')} />

            <div className='location-admission'>
                <p>{t('locationPage.free_admission')}</p>
                <p>{t('locationPage.ticket')}</p>
            </div>
        </div>
    )
}

export default Location