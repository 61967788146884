import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../Header/PageHeader';
import Expire from "./Overlay";
import emailjs from '@emailjs/browser';

const Contact = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        senderEmail: "",
        phoneNumber: "",
        subject: "",
        category: "",
        messageText: ""
    })

    useEffect(() => {
        if (show) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'unset';
    }, [show]);

    const sendEmail = (e) => {
        e.preventDefault();

        setShow(true);

        emailjs.sendForm('service_3qud6er', 'template_mfrw1n2', '#emailForm', 'user_cBRJCMlt8S7GG73F375Ob')
            .then((result) => {
                setShow(false);
                setForm({
                    firstName: "",
                    lastName: "",
                    senderEmail: "",
                    phoneNumber: "",
                    subject: "",
                    category: "",
                    messageText: ""
                })
            }, (error) => {
                console.log(error.text);
                setShow(false);
            });
    }

    return (
        <>
            <div className='container contact-form'>
                <PageHeader header={t('gq')} title={t('contact_page.contact')} />

                <form onSubmit={sendEmail} id='emailForm'>
                    <div className='row'>
                        <div className='col-12 col-sm-5'>
                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='firstName'
                                    placeholder=' '
                                    onChange={e => setForm({ firstName: e.target.value })}
                                    required />
                                <label className='input-label'>{t('contact_page.first_name')}</label>
                            </div>
                        </div>

                        <div className='col'></div>

                        <div className='col-12 col-sm-5'>
                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='lastName'
                                    placeholder=' '
                                    onChange={e => setForm({ lastName: e.target.value })}
                                    required />
                                <label className='input-label'>{t('contact_page.last_name')}</label>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12 col-sm-5'>
                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='senderEmail'
                                    placeholder=' '
                                    onChange={e => setForm({ senderEmail: e.target.value })}
                                    required />
                                <label className='input-label'>{t('contact_page.email')}</label>
                            </div>
                        </div>

                        <div className='col'></div>

                        <div className='col-12 col-sm-5'>
                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='phoneNumber'
                                    placeholder=' '                                  
                                    onChange={e => setForm({ phoneNumber: e.target.value })}
                                    />
                                <label className='input-label'>{t('contact_page.number')}</label>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12 col-sm-5'>
                            <div className='outlined-textfield'>
                                <input
                                    className='input-border'
                                    type='text'
                                    name='subject'
                                    placeholder=' '
                                    onChange={e => setForm({ subject: e.target.value })}
                                    required />
                                <label className='input-label'>{t('contact_page.subject')}</label>
                            </div>
                        </div>

                        <div className='col'></div>

                        <div className='col-12 col-sm-5'>
                            <div className='outlined-textfield'>
                                <select className='input-border'
                                    name="category"
                                    required
                                    onChange={e => setForm({ category: e.target.value })}>
                                    <option value="" selected disabled>Please select</option>
                                    <option value={t('contact_page.exhibition')}>{t('contact_page.exhibition')}</option>
                                    <option value={t('contact_page.support')}>{t('contact_page.support')}</option>
                                    <option value={t('contact_page.collection')}>{t('contact_page.collection')}</option>
                                    <option value={t('contact_page.commision')}>{t('contact_page.commision')}</option>
                                    <option value={t('contact_page.gogo_club')}>{t('contact_page.gogo_club')}</option>
                                    <option value={t('contact_page.art_talks')}>{t('contact_page.art_talks')}</option>
                                    <option value={t('contact_page.worshops')}>{t('contact_page.worshops')}</option>
                                    <option value={t('contact_page.residencies')}>{t('contact_page.residencies')}</option>
                                    <option value={t('contact_page.open_call')}>{t('contact_page.open_call')}</option>
                                </select>
                                <label className='input-label'>{t('contact_page.select')}</label>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className='outlined-textfield'>
                                <textarea
                                    className='input-border textarea-border'
                                    name='messageText'
                                    rows='4'
                                    cols='50'
                                    placeholder=' '
                                    onChange={e => setForm({ messageText: e.target.value })}
                                    required />
                                <label className='input-label textarea-label'>{t('contact_page.message')}</label>
                            </div>

                            {/* <div className='checkbox'>
                                <label class="container">
                                    {t('contact_page.subscribe')}
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </label>
                            </div> */}
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="btn btn-blue"
                    > {t('contact_page.submit')}</button>
                </form>
            </div>
            {show &&
                <Expire delay="10000">
                    <h2>{t('contact_page.thanks')}</h2>
                    <p>{t('contact_page.reply')}</p>
                </Expire>}
        </>
    );
};

export default Contact