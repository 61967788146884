const PageHeader = (props) => {
    return (
        <div className="page-header">
            <h4 className='page-category'>{props.header}</h4>

            <h1 className='page-title'>{props.title}</h1>
        </div>
    )
}

export default PageHeader;